
@media screen and (max-width: 600px) {
    .popup {
        top: 30vh !important;
    }
}
@media screen and (max-width: 800px) {
    .popup {
        width: 80vw !important;
        height: 80vw !important;
    }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
    .popup {
        width: 50vw !important;
        height: 50vw !important; 
    }
}

.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40vw;
    height: 40vw;
    max-height: 80vh;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10vh;
    border-radius: 1em;
    box-shadow: var(--boxShadow);  
}
.popup span {
    font-size: 0.9em;
    width: 90%;
    max-height: 75%;
    overflow: auto;
}
.popup img {
    width: 2em;
    position: absolute;
    top: 1em;
}
.popup img:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.popup pre {
    text-align: left;
    font-size: 0.7em;
    max-width: 90%;
    max-height: 75%;
    word-wrap: break-word;
    overflow: auto;
    padding: 0.5em;
}
.popup button {
    background: var(--bgColor);
    margin: 0px 1em;
}

.hithere {
    animation: hithere 0.5s ease;
}
@keyframes hithere {
    30% { transform: scale(1.1); }
    40%, 60% { transform: rotate(-10deg) scale(1.1); }
    50% { transform: rotate(10deg) scale(1.1); }
    70% { transform: rotate(0deg) scale(1.1); }
    100% { transform: scale(1); }
}

.popupStatus {
    position: absolute;
    top: 95%;
    left: 50%;
    font-size: 0.7em;
}

.enlargedPopup {
    max-height: unset !important;
    height: 90vh !important;
    width: 90vw !important;
    top: 5vh !important;
}